.Images {
    margin: 5px;
    height: 80px;
    width: 80px;
}

.EmailImage {
    margin: 5px;
    height: 80px;
    width: 95px;
}

.div {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: "Courier";
    font-size: 20px;
    font-weight: bold;
}

.table {
    font-family: "Courier";
    border-collapse: collapse;
    font-size: 20px;
    padding: 5px
}

.th {
    text-align: right;
    font-size: 25px;
    font-weight: bold;

    /* border: 2px solid black; */
    border-collapse: collapse;
    padding: 5px;
}

.td {
    text-align: center;
    font-weight: bold;

    /* border: 2px solid black; */
    border-collapse: collapse;
    font-size: 20px;
    padding: 5px;

}

.paragraph {
    font-family: "Courier";
    font-size: 20px;
    font-weight: bold;
}

.divCenter {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.mainDiv {
    height: 100%;
 
}



@media (max-width: 768px) {

    .EmailImage {
        margin: 2px;
        height: 60px;
        width: 71px;
    }

    .Images {
        margin: 2px;
        height: 60px;
        width: 60px;
    }
    

    .td {
        font-weight: bold;
        font-size: 15px;
        padding: 5px;
    
    }

    .div {
        margin-top: 30px;
        width: 360px;
        font-family: "Courier";
        font-size: 15px;
    }
   
}