.table {
    font-family: "Courier";
    /* border: 2px solid black; */
    border-collapse: collapse;
    font-size: 20px;
    padding: 5px
}

.th {
    text-align: right;
    font-size: 25px;
    font-weight: bold;

    /* border: 2px solid black; */
    border-right: 4px solid black;
    border-collapse: collapse;
    padding: 20px;
}

.td {
    text-align: center;
    font-weight: bold;
    border-collapse: collapse;
    font-size: 20px;
    padding-left: 20px;
}

.Images {
    height: 70px;
    width: 70px;
}

.div {
    margin-top: 50px;
    border: 0px solid red;
    display: flex;
    justify-content: center;
}


@media (max-width: 768px) {
    .Images {
        height: 20px;
        width: 20px;
    }

    .th {
       
        font-size: 13px;
        border-right: 2px solid black;
        padding: 5px;
    }
    
    .td {
        font-size: 10px;
        padding-left: 5px;
    }

    .table {
        margin-top: 15%;
    }
}