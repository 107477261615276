.h1 {
   
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;


    font-family: 'Courier New', monospace;
    font-weight: bold;
    
}




@media (max-width: 768px) {
    h1 {
        font-size: 20px;
        color: black;
        padding-top: 15px;
        padding-bottom: 15px;

    }
}

