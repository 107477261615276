.container {
    position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    background-color: rgba(33, 37, 41, 255);
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    max-height: 60px;
  }
  

  
  p {
    font-size: 26px;
    min-width: 400px;
    color: rgba(155, 157, 158, 255);
    font-family: "Courier"; 
    text-align: center;   
  }
  
  @media (max-width: 768px) {
    .container {
        position:fixed;       
        width: 100%;
        background-color: rgba(33, 37, 41, 255);
        max-width: 100%;
        text-align: center;
    }

  
    p {
      font-size: 16px; /* Decrease font size for smaller screens */
      color: rgba(155, 157, 158, 255);
      font-family: "Courier";  
      height: 40px;
      
    
    }
  }
  