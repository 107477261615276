.mainDiv {
  margin-left: 300px;
  margin-right: 300px;
}

.paragraph {
  text-indent: 40px;
  font-weight: bold;
}

.unorderedList {
  text-indent: 0px;
  font-weight: bold;
}


@media (max-width: 768px) {
  .mainDiv {
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 100px;
  }

  .paragraph {
    text-indent: 20px;
  }
  



}