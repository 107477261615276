.imageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileImage {
    width: 18%;
}

.slidingContainer {
    display: flex;
    /* Use flexbox layout */
    justify-content: center;
    /* Center the child horizontally */
    align-items: center;
    /* Center the child vertically */


}

.slidingDiv {
    text-align: center;
    overflow: hidden;
    padding-top: 30px;
    width: 511px;
}

.slidingText {
    font-family: 'Courier New', monospace;
    /* Use a monospace font */
    font-size: 20px;
    font-weight: bold;
    /* Set font size */
    color: black;
    /* Text color */
    /* Simulate typing cursor */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowing text */
    animation: hacker-type 3s steps(30) 1;
    /* Apply hacker-type animation */
}

@keyframes hacker-type {
    0% {
        width: 0;
        /* Start with no width (text hidden) */
    }

    100% {
        width: 100%;
        /* Fully reveal the text */
    }
}


@media (max-width: 768px) {
    .profileImage {
        width: 60%;
        height: 60%;
    }

    .slidingText {
        font-size: 12px;
    }

    .slidingDiv {
        width: 310px;
        padding-top: 20px;
    }
}