.mainDiv {
    height: 100%;
 
 
}

.titleDiv {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carouselDiv {
    /* margin-left: 500px; */
    
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}

.width {
    width: 1000px;
}

.h32 {
    color: rgb(100, 0, 0);
    margin: 0px;

}

.image {
    height: 600px;
    width: 1000px;
}


@media (max-width: 768px) {

    .image {
        height: 240px;
        width: 400px;
    }

    .width {
        width: 400px;
    }

    .carouselDiv {
        height: 300px;
        width: 100%;
        /* margin-left: 2%; */

    }

    .h32 {
        font-size: 14px;
        padding: 0px;
        margin: 0px;
    }

   
}

